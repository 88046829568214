import { ReclaimEditionStr, ReclaimEditionV2, SubscriptionFrequencyStr } from "./Team.types";

export const EDITION_V2_ORDER: readonly ReclaimEditionV2[] = Object.freeze([
  "LITE",
  "STARTER",
  "BUSINESS",
  "ENTERPRISE",
]);
export const EDITION_META: Readonly<Record<ReclaimEditionStr, { label: string; isTrial?: true }>> = Object.freeze({
  ASSISTANT: { label: "Assistant" },
  BUSINESS: { label: "Business" },
  ENTERPRISE: { label: "Enterprise" },
  LEGACY_PRO_TRIAL: { label: "Pro", isTrial: true },
  LEGACY_TEAM_TRIAL: { label: "Team", isTrial: true },
  LITE: { label: "Lite" },
  NONE: { label: "" },
  PRO: { label: "Pro" },
  REWARD: { label: "Team" },
  STARTER: { label: "Starter" },
  TEAM: { label: "Team" },
  TRIAL: { label: "Team", isTrial: true },
  TRIAL_BUSINESS: { label: "Business", isTrial: true },
});

export const SUB_FREQUENCY_ORDER: readonly SubscriptionFrequencyStr[] = Object.freeze(["MONTH", "YEAR"]);
export const SUB_FREQUENCY_META: Readonly<Record<SubscriptionFrequencyStr, { label: string }>> = Object.freeze({
  MONTH: { label: "month" },
  YEAR: { label: "year" },
});
